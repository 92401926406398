import { Fragment } from "react";
import Title from "../../UI/box/title/Title";

const links = [
  {
    name: "Σύνδεση",
    url: "https://cityzenapp.zitsa.gov.gr/cityzen/zitsa/login",
  },
  {
    name: "Εγγραφή",
    url: "https://cityzenapp.zitsa.gov.gr/cityzen/zitsa/register",
  },
  { name: "Πληροφορίες", url: "https://cityzenapp.zitsa.gov.gr/cityzen/zitsa" },
];

const Applicants = () => {
  return (
    <Fragment>
      <Title
        icon={"im im-icon-Business-ManWoman"}
        title={"ΗΛΕΚΤΡΟΝΙΚΗ ΥΠΟΒΟΛΗ ΑΙΤΗΜΑΤΩΝ"}
        infoContext={"Δημιουργήστε λογαριασμό και κάντε αναφορές για τα θέματα που παρατηρείτε στο Δήμο μας και χρήζουν της προσοχής ή της παρέμβασης από τις υπηρεσίες μας"}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
      />
      <div style={{ marginTop: 30 }} />
      {links.map((item, index) => (
        <button
          key={index}
          type={"button"}
          style={{
            width: 200,
            height: 35,
            marginLeft: "calc((100% - 200px ) /2)",
            marginBottom: 10,
            borderRadius: "30px",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => window.open(item.url, "_blank")}
        >
          <span style={{ fontWeight: "600", fontSize: "15px" }}>
            {item.name}
          </span>
        </button>
      ))}
    </Fragment>
  );
};
export default Applicants;
