import React from "react";
import classes from "./Tooltip.module.css";

const Tooltip = (props) => {
  return (
    <React.Fragment>
      <p id="tooltip" className={[classes.tooltip, props.className].join(" ")}>
        {props.tooltipContent}
      </p>
    </React.Fragment>
  );
};

export default Tooltip;
