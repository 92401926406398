import React, { useEffect, useState, useRef } from "react";
import classes from "./../containers/map_placeholder/MapContainer.module.css";

import city from "./../assets/images/city.png";

import HorizontalSidePanel from "../components/UI/panel/HorizontalSidePanel";

import AlertsPlaceholder from "../containers/alerts_placeholder/AlertsPlaceholder";

import SmartisCityMap from "../components/map/SmartisCityMap";
import VerticalSidePanel from "../components/UI/panel/VerticalSidePanel";
import VerticalAlerts from "../components/forms/vertical/VerticalAlerts";
import LegendFixed from "../components/layout/legend/LegendFixed";

const cityCenter = {
  lat: 39.7051678,
  lng: 20.7887576,
};
let listener = null;
const Alerts = () => {
  const [showInfoPanel, setShowInfoPanel] = useState(false);
  const openedValue = useRef(null);

  const [alertsList, setAlertsList] = useState([]);

  const close = () => {
    setShowInfoPanel(false);
  };

  const showAnimation = (value) => {
    console.log(showInfoPanel, value, openedValue.current);
    if (showInfoPanel && value === openedValue.current) return;
    if (showInfoPanel && value) {
      // if (value === openedValue.current) return;
      clearTimeout(listener);
      setShowInfoPanel(false);
      listener = setTimeout(() => setShowInfoPanel(true), 250);
    } else {
      setShowInfoPanel(true);
    }
    openedValue.current = value;
  };

  useEffect(() => {
    return () => clearTimeout(listener);
  }, []);

  useEffect(() => {
    getAlertsList().catch((error) => console.warn(error));
    setInterval(() => {
      schedulerGetAlertList();
    }, 15000);

    return () => clearInterval(schedulerGetAlertList);
  }, []);

  const schedulerGetAlertList = () => {
    getAlertsList().catch((error) => console.warn(error));
  };

  const getAlertsList = async () => {
    const response = await fetch(
      `https://smartcity.zitsa.gov.gr/api/api.php?func=alertslist`
    );

    if (response === undefined || !response.ok) return;

    const data = await response.json();
    setAlertsList(data);
  };

  return (
    <div style={{ marginTop: 70 }}>
      <div className={classes.pseudo_header}>
        <img src={city} alt={"logo"} />
      </div>
      <LegendFixed />
      <HorizontalSidePanel className={classes.alerts_width}>
        <AlertsPlaceholder
          showAnimation={showAnimation}
          close={close}
          alertsList={alertsList}
        />
      </HorizontalSidePanel>
      <VerticalSidePanel
        className={classes.width500}
        show={showInfoPanel}
        close={close}
      >
        <VerticalAlerts />
      </VerticalSidePanel>
      <div className={classes.wrapper_header}>
        <SmartisCityMap cityCenter={cityCenter} showAnimation={showAnimation} />
      </div>
    </div>
  );
};

export default Alerts;
