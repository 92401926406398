import classes from "./BoxPlaceholder.module.css";
import { useEffect, useState, useCallback } from "react";
import Box from "./../../components/UI/box/Box";
import Meshlium from "../../components/forms/data/meshlium/Meshlium";
import Meteo from "./../../components/forms/data/meteo/Meteo";
import { MuuriComponent } from "muuri-react";
import "./BoxPlaceholder.css";
import UVForm from "../../components/forms/data/UVForm";
import Weather from "../../components/forms/data/weather/Weather";
import MapPOIS from "../../components/forms/data/mapPOIS/MapPOIS";
import MapStore from "../../components/forms/data/mapStoreFrame/MapStore";
import Diav from "../../components/forms/data/Diav";
import Applicants from "../../components/forms/data/Applicants";

const maxEntitiesLargeBox = 9;
const maxEntitiesMediumBox = 2;
const baseUrl = "https://zitsa.smartiscity.gr";
const uvURL = baseUrl + "/api/api.php?func=indexes";

const options = {
  dragSortHeuristics: {
    sortInterval: 0,
  },
  layoutDuration: 400,
  dragRelease: {
    duration: 400,
    easing: "ease-out",
  },

  dragEnabled: true,
  dragStartPredicate: function (item, hammerEvent) {
    if (hammerEvent.target.matches("#draggable")) {
      return true;
    }
    return false;
    // distance: 0,
    // delay: 500,
  },
  dragContainer: document.body,
  // The placeholder of an item that is being dragged.
  dragPlaceholder: {
    enabled: true,
    createElement: function (item) {
      // The element will have the Css class ".muuri-item-placeholder".
      return item.getElement().cloneNode(true);
    },
  },
};

function useFilter(value, search) {
  return useCallback(
    function (data) {
      var isSearchMatch = !search
        ? true
        : data.title.toLowerCase().indexOf(search) > -1;
      var isFilterMatch = value === "ΟΛΑ" ? true : data.title === value;
      return isSearchMatch && isFilterMatch;
    },
    [value, search]
  );
}

const BoxPlaceholder = () => {
  const [uvData, setUvData] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
    value: "ΟΛΑ",
  });

  const controller = new AbortController();
  const { signal } = controller;

  useEffect(() => {
    const getUVMetrics = async () => {
      const req = await fetch(uvURL, { signal }).catch((error) =>
        console.warn(error)
      );

      if (req === undefined || !req.ok) return;

      const data = await req.json();

      setUvData(data);
      console.log(data);
    };

    getUVMetrics();
  }, []);

  const filterFunction = useFilter(filter.value, filter.search);

  return (
    <div className={classes.box_placeholder}>
      {/* <select onChange={(e) => setFilter({ ...filter, value: e.target.value })}>
        <option value="ΟΛΑ">Όλα</option>
        <option value="ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ">Περιβάλλον</option>
        <option value="ΕΝΕΡΓΕΙΑ">Ενέργεια</option>
        <option value="ΕΞΥΠΗΡΕΤΗΣΗ">Εξυπηρέτηση</option>
        <option value="ΚΙΝΗΤΙΚΟΤΗΤΑ">Κινητικότητα</option>
        <option value="ΕΦΑΡΜΟΓΕΣ">Εφαρμογές ΓΠΣ</option>
      </select> */}
      <MuuriComponent
        {...options}
        propsToData={({ color, title }) => ({ color, title })}
        filter={filterFunction}
      >
        <Box className={classes.large} typeId="1" title="ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ">
          <Meteo
            maxEntities={maxEntitiesLargeBox}
            typeId="1"
            title="ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ ΔΕΔΟΜΕΝΑ"
            showSubTitle={true}
          />
        </Box>

        {/* MAP VEHICLES */}
        <Box className={classes.map_box} typeId="8" title="ΚΙΝΗΤΙΚΟΤΗΤΑ">
          <MapPOIS typeId="8" title="ΔΙΑΧ/ΣΗ ΣΤΟΛΟΥ" />
        </Box>

        {/* MAP BINS */}
        <Box className={classes.map_box} typeId="9" title="ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ">
          <MapPOIS typeId="9" title="ΚΑΔΟΙ" />
        </Box>

        {/* MAP HYDRO */}
        {/*<Box className={classes.map_box} typeId="10" title="ΕΝΕΡΓΕΙΑ">*/}
        {/*  <MapPOIS typeId="10" title="ΥΔΡΟΜΕΤΡΑ" />*/}
        {/*</Box>*/}

        <Box className={classes.large} typeId="5" title="ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ">
          <Meteo
            maxEntities={maxEntitiesLargeBox}
            typeId="5"
            title="ΜΕΤΕΩΡΟΛΟΓΙΚΑ ΔΕΔΟΜΕΝΑ"
            showSubTitle={false}
          />
        </Box>

        {/* Frames Map */}
        <Box
          className={[classes.medium, classes.frameBox].join(" ")}
          title="ΕΞΥΠΗΡΕΤΗΣΗ"
        >
          <MapStore order={1} />
        </Box>

        <Box
          className={[classes.medium, classes.uv_style].join(" ")}
          typeId="5"
        >
          <UVForm data={uvData[0]} typeId="5" />
        </Box>

        <Box
          className={[classes.medium, classes.frameBox].join(" ")}
          title="ΕΞΥΠΗΡΕΤΗΣΗ"
        >
          <MapStore order={2} />
        </Box>

        {/* <Box className={classes.large} typeId="6">
          <Meteo
            maxEntities={maxEntitiesLargeBox}
            typeId="6"
            title="ΠΟΙΟΤΗΤΑ ΥΔΑΤΩΝ"
            showSubTitle={true}
          />
        </Box> */}
        {/* <Box className={classes.medium} typeId="2">
          <Meshlium maxEntities={maxEntitiesMediumBox} previewGroup="1" />
        </Box>
        <Box className={classes.medium} typeId="2">
          <Meshlium maxEntities={maxEntitiesMediumBox} previewGroup="2" />
        </Box>
        <Box className={classes.medium} typeId="2">
          <Meshlium maxEntities={maxEntitiesMediumBox} previewGroup="3" />
        </Box> */}

        {/* Diavouleuseis */}
        <Box
          className={[classes.medium, classes.diav].join(" ")}
          title="ΕΞΥΠΗΡΕΤΗΣΗ"
        >
          <Diav />
        </Box>

        {/* Weather */}
        <Box
          className={[classes.medium, classes.weatherBox].join(" ")}
          title="ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ"
        >
          <Weather />
        </Box>

        {/* UV METRICS */}

        <Box
          className={[classes.medium, classes.uv_style].join(" ")}
          typeId="5"
        >
          <UVForm data={uvData[1]} typeId="5" />
        </Box>
        <Box className={classes.medium} typeId="1">
          <UVForm data={uvData[3]} typeId="1" />
        </Box>
        <Box
          className={[classes.medium, classes.uv_style].join(" ")}
          typeId="5"
        >
          <UVForm data={uvData[2]} typeId="5" />
        </Box>
        {/* <Box className={classes.medium} typeId="1">
          <UVForm data={uvData[3]} typeId="1" />
        </Box> */}

        {/* MAP POIS */}
        {/* <Box className={classes.map_box} typeId="7">
          <MapPOIS typeId="7" title="ΣΗΜΕΙΑ ΕΝΔΙΑΦΕΡΟΝΤΟΣ" />
        </Box> */}

        {/* MAP WIFIHOTSPOT */}
        {/* <Box className={classes.map_box} typeId="4">
          <MapPOIS typeId="4" title="WIFI HOTSPOTS" />
        </Box> */}

        {/* Applicants */}
        <Box
          className={[classes.medium, classes.applicants].join(" ")}
          title="ΕΞΥΠΗΡΕΤΗΣΗ"
        >
          <Applicants />
        </Box>
        {/* Applicants */}
      </MuuriComponent>
    </div>
  );
};

export default BoxPlaceholder;
