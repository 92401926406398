// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import classes from "./CustomSlider.module.css";
// import "./CustomSlider.css";

// const settings = {
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
// };

// const CustomSlider = (props) => {
//   return (
//     <Slider className={classes.slider} {...settings}>
//       {props.children}
//     </Slider>
//   );
// };

// export default CustomSlider;

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from "./CustomSlider.module.css";
import "./CustomSlider.css";
import { useEffect, useState } from "react";

const defaultSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const CustomSlider = (props) => {
  const settings =
    "settings" in props
      ? { ...defaultSettings, ...props.settings }
      : defaultSettings;

  return (
    <Slider className={classes.slider} {...settings}>
      {props.children}
    </Slider>
  );
};

export default CustomSlider;
