import { initStore } from "./store";
import enableDisableActive from "./../services/enableDisableActive";

const configureStore = () => {
  console.log("Configure");
  const actions = {
    CLICKED_TYPE: (curState, typeId) => {
      const typeIndex = curState.types.findIndex((t) => t.id === typeId);
      const tempTypes = [...curState.types];
      const updatedTypes = enableDisableActive(tempTypes, typeIndex);

      return { types: updatedTypes };
    },
    INITIALIZE_TYPES: (state, types) => ({ types: types }),
  };

  initStore(actions, { types: [] });
};
export default configureStore;
