import React from "react";
import MapContainer from "./../containers/map_placeholder/MapContainer";
import SmartisCityMap from "./../components/map/SmartisCityMap";
// Αλεξανδρούπολη
// const cityCenter = {
//   lat: 40.8472598,
//   lng: 25.8847129,
// };

// Πρέβεζα - Κοινότητα
// const cityCenter = {
//   lat: 38.957364210005615,
//   lng: 20.754522551519024,
// };

// Δράμα
// const cityCenter = {
//   lat: 41.144171,
//   lng: 24.1473084,
// };

// Ζίτσα
const cityCenter = {
  lat: 39.7051678,
  lng: 20.7887576,
};

const Map = (props) => {
  return (
    <MapContainer mapFrame={props.mapFrame ? props.mapFrame : null}>
      <SmartisCityMap cityCenter={cityCenter} />
    </MapContainer>
  );
};

export default Map;
