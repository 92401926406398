import classes from "./Box.module.css";
import { useEffect, useState } from "react";
import { useStore } from "./../../../hooks/store";

const Medium = (props) => {
  const [bgColor, setBgColor] = useState(null);
  const state = useStore()[0];
  useEffect(() => {
    if (state.types.length > 0 && props.typeId) {
      const indexP = state.types.findIndex((item) => item.id === props.typeId);
      if (indexP !== -1) {
        setBgColor({ backgroundColor: state.types[indexP].color });
      }
    }
  }, [state.types, props.typeId]);

  return (
    <div
      className={[classes.box, props.className].join(" ")}
      style={bgColor ? bgColor : null}
      onClick={() => props.onClick && props.onClick()}
    >
      {props.children}
    </div>
  );
};

export default Medium;
