import classes from "./LogTable.module.css";

const LogTable = () => {
  return (
    <section className={classes.wrapper}>
      <div className={[classes.row, classes.title].join(" ")}>
        <ul>
          <li>Sport</li>
          <li>Entry $</li>
          <li>
            <span className={classes["title-hide"]}>#</span> Entries
          </li>
          <li>Max</li>
          <li>Time</li>
        </ul>
      </div>

      <section className={classes["row-fadeIn-wrapper"]}>
        <article
          className={[classes.row, classes.fadeIn, classes.nfl].join(" ")}
        >
          <ul>
            <li>
              <a href="#">NFL</a>
              <span className={classes.small}>(fadeIn)</span>
            </li>
            <li>$50</li>
            <li>12</li>
            <li>48</li>
            <li>2:00ET</li>
          </ul>
          <ul className={classes["more-content"]}>
            <li>
              This 1665-player contest boasts a $300,000.00 prize pool and pays
              out the top 300 finishing positions. First place wins $100,000.00.
              Good luck!
            </li>
          </ul>
        </article>
      </section>

      <section className={classes["row-fadeOut-wrapper"]}>
        <article className={[classes.row, classes.nfl].join(" ")}>
          <ul>
            <li>
              <a href="#">NFL</a>
              <span className={classes.small}>(fadeOut)</span>
            </li>
            <li>$5</li>
            <li>45</li>
            <li>100</li>
            <li>3:00ET</li>
          </ul>
          <ul className={classes["more-content"]}>
            <li>
              This 1665-player contest boasts a $300,000.00 prize pool and pays
              out the top 300 finishing positions. First place wins $100,000.00.
              Good luck!
            </li>
          </ul>
        </article>
      </section>

      <article className={[classes.row, classes.nfl].join(" ")}>
        <ul>
          <li>
            <a href="#">NHL</a>
          </li>
          <li>$50</li>
          <li>12</li>
          <li>48</li>
          <li>12:00ET</li>
        </ul>
        <ul className={classes["more-content"]}>
          <li>
            This 1665-player contest boasts a $300,000.00 prize pool and pays
            out the top 300 finishing positions. First place wins $100,000.00.
            Good luck!
          </li>
        </ul>
      </article>

      <article
        className={[classes.row, classes.mlb, classes["update-row"]].join(" ")}
      >
        <ul>
          <li>
            <a href="#">MLB</a>
            <span className={classes.small}>(update)</span>
          </li>
          <li>$10</li>
          <li>
            <span className={classes.update1}>1</span>
            <span className={classes.update2}>2</span>
          </li>
          <li>10</li>
          <li>1:00ET</li>
        </ul>
        <ul className={classes["more-content"]}>
          <li>
            This 1665-player contest boasts a $300,000.00 prize pool and pays
            out the top 300 finishing positions. First place wins $100,000.00.
            Good luck!
          </li>
        </ul>
      </article>

      <article className={[classes.row, classes.mlb].join(" ")}>
        <ul>
          <li>
            <a href="#">MLB</a>
          </li>
          <li>$5</li>
          <li>48</li>
          <li>120</li>
          <li>12:00ET</li>
        </ul>
        <ul className={classes["more-content"]}>
          <li>
            This 1665-player contest boasts a $300,000.00 prize pool and pays
            out the top 300 finishing positions. First place wins $100,000.00.
            Good luck!
          </li>
        </ul>
      </article>

      <article className={[classes.row, classes.nhl].join(" ")}>
        <ul>
          <li>
            <a href="#">NHL</a>
          </li>
          <li>$50</li>
          <li>12</li>
          <li>48</li>
          <li>12:00ET</li>
        </ul>
        <ul className={classes["more-content"]}>
          <li>
            This 1665-player contest boasts a $300,000.00 prize pool and pays
            out the top 300 finishing positions. First place wins $100,000.00.
            Good luck!
          </li>
        </ul>
      </article>

      <article className={[classes.row, classes.nhl].join(" ")}>
        <ul>
          <li>
            <a href="#">NHL</a>
          </li>
          <li>$50</li>
          <li>12</li>
          <li>48</li>
          <li>12:00ET</li>
        </ul>
        <ul className={classes["more-content"]}>
          <li>
            This 1665-player contest boasts a $300,000.00 prize pool and pays
            out the top 300 finishing positions. First place wins $100,000.00.
            Good luck!
          </li>
        </ul>
      </article>

      <article className={[classes.row, classes.pga].join(" ")}>
        <ul>
          <li>
            <a href="#">PGA</a>
          </li>
          <li>$50</li>
          <li>12</li>
          <li>48</li>
          <li>11:00ET</li>
        </ul>
        <ul className={classes["more-content"]}>
          <li>
            This 1665-player contest boasts a $300,000.00 prize pool and pays
            out the top 300 finishing positions. First place wins $100,000.00.
            Good luck!
          </li>
        </ul>
      </article>

      <article className={[classes.row, classes.pga].join(" ")}>
        <ul>
          <li>
            <a href="#">PGA</a>
          </li>
          <li>$50</li>
          <li>12</li>
          <li>48</li>
          <li>11:00ET</li>
        </ul>
        <ul className={classes["more-content"]}>
          <li>
            This 1665-player contest boasts a $300,000.00 prize pool and pays
            out the top 300 finishing positions. First place wins $100,000.00.
            Good luck!
          </li>
        </ul>
      </article>

      <article className={[classes.row, classes.pga].join(" ")}>
        <ul>
          <li>
            <a href="#">PGA</a>
          </li>
          <li>$50</li>
          <li>12</li>
          <li>48</li>
          <li>11:00ET</li>
        </ul>
        <ul className={classes["more-content"]}>
          <li>
            This 1665-player contest boasts a $300,000.00 prize pool and pays
            out the top 300 finishing positions. First place wins $100,000.00.
            Good luck!
          </li>
        </ul>
      </article>

      <article className={[classes.row, classes.mlb].join(" ")}>
        <ul>
          <li>
            <a href="#">MLB</a>
          </li>
          <li>$10</li>
          <li>1</li>
          <li>10</li>
          <li>1:00ET</li>
        </ul>
        <ul className={classes["more-content"]}>
          <li>
            This 1665-player contest boasts a $300,000.00 prize pool and pays
            out the top 300 finishing positions. First place wins $100,000.00.
            Good luck!
          </li>
        </ul>
      </article>
    </section>
  );
};
export default LogTable;
