import { useState } from "react";
import VerticalAlertControlCenter from "./VerticalAlertControlCenter";
import VerticalHistory from "./VerticalHistory";
import classes from "./VerticalSwitch.module.css";

const VerticalSwitch = ({ alertDetails }) => {
  const [actionsEnable, setActionsEnable] = useState(true);
  return (
    <>
      <div className={classes.vertical_swticher}>
        <h1>{actionsEnable ? "ΕΝΕΡΓΕΙΕΣ" : "ΙΣΤΟΡΙΚΟ"}</h1>
        <button type="button" onClick={() => setActionsEnable((prev) => !prev)}>
          {!actionsEnable ? "ΕΝΕΡΓΕΙΕΣ" : "ΙΣΤΟΡΙΚΟ"}
        </button>
      </div>
      {actionsEnable && (
        <VerticalAlertControlCenter alertDetails={alertDetails} />
      )}
      {!actionsEnable && (
        <VerticalHistory alertHistory={alertDetails.history} />
      )}
    </>
  );
};

export default VerticalSwitch;
