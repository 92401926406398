import classes from "./Footer.module.css";
import appleMini from "./../../../assets/images/apple_mini.png";
import androidMini from "./../../../assets/images/android_mini.jpg";
import information from "./../../../assets/images/information_project.png";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <p className={classes.copyright}>&copy;{new Date().getFullYear()} Dotsoft</p>
      <div>
        <img
          src={information}
          alt={"information-application"}
          onClick={() =>
            window.open("https://www.smartiscity.gr/dimos-zitsas/")
          }
        />
         <img
          src={appleMini}
          alt={"apple-application"}
          onClick={() =>
            window.open("https://apps.apple.com/tt/app/%CE%B4%CE%AE%CE%BC%CE%BF%CF%82-%CE%B6%CE%AF%CF%84%CF%83%CE%B1%CF%82/id1593597545")
          }
        />
        <img
          src={androidMini}
          alt={"android-application"}
          onClick={() =>
            window.open("https://play.google.com/store/apps/details?id=gr.dotsoft.cityzenapp.zitsa")
          }
        />
      </div>
    </div>
  );
};

export default Footer;