import classes from "./AlertsPlaceholder.module.css";

import { MuuriComponent } from "muuri-react";
import { useCallback, useEffect, useState } from "react";
import Box from "./../../components/UI/box/Box";
import repeatIcon from "./../../assets/images/repeat.png";
import AlertCardHandler from "../../components/forms/data/alerts/AlertCardHandler";

const options = {
  dragSortHeuristics: {
    sortInterval: 0,
  },
  layoutDuration: 400,
  dragRelease: {
    duration: 400,
    easing: "ease-out",
  },
  dragEnabled: true,
  dragStartPredicate: function (item, hammerEvent) {
    return !!hammerEvent.target.matches("#draggable");

    // distance: 0,
    // delay: 500,
  },
  dragContainer: document.body,
  // The placeholder of an item that is being dragged.
  dragPlaceholder: {
    enabled: true,
    createElement: function (item) {
      // The element will have the Css class ".muuri-item-placeholder".
      return item.getElement().cloneNode(true);
    },
  },
};

function useFilter(value, search) {
  return useCallback(
    function (data) {
      const isSearchMatch = !search
        ? true
        : data.title.toLowerCase().indexOf(search) > -1;
      const isFilterMatch = value === "ΟΛΑ" ? true : data.title === value;
      return isSearchMatch && isFilterMatch;
    },
    [value, search]
  );
}

const AlertsPlaceholder = (props) => {
  const [filter, setFilter] = useState({
    search: "",
    value: "ΟΛΑ",
  });

  const [alarmBox, setAlarmBox] = useState(false);
  const [cardOpen, setCardOpen] = useState(-1); /* All cards closed */

  const filterFunction = useFilter(filter.value, filter.search);

  const cardOpenHandler = (id) => {
    if (cardOpen) setCardOpen(id);
  };
  const closeCards = () => setCardOpen(-2);
  const openCards = () => setCardOpen(-1);

  useEffect(() => {
    setTimeout(() => setAlarmBox(true), 3000);
  }, []);

  return (
    <div className={classes.stats_box_placeholder}>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: "1",
          cursor: "pointer",
        }}
        onClick={() => {
          if (cardOpen === -1) closeCards();
          else openCards();
        }}
      >
        <img src={repeatIcon} alt={"flip cards"} />
      </div>
      <MuuriComponent
        {...options}
        propsToData={({ color, title }) => ({ color, title })}
        filter={filterFunction}
      >
        <Box
          className={
            props.alertsList.filter(
              (item) => item.categ_id === "1" && item.history.length === 0
            ).length > 0
              ? [classes.alert_box, classes.alarm_box].join(" ")
              : classes.alert_box
          }
          title="ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ"
          onClick={() => cardOpen !== 8 && cardOpen !== -1 && setCardOpen(8)}
        >
          {/*{cardOpen === 0 && (*/}
          {/*  <AlertsForm*/}
          {/*    typeId="8"*/}
          {/*    title="ΔΙΑΧ/ΣΗ ΣΤΟΛΟΥ"*/}
          {/*    className={classes.fleeto_bg_color}*/}
          {/*  />*/}
          {/*)}*/}
          {/*{cardOpen === -2 && <AlertClose title={"ΔΙΑΧ/ΣΗ ΣΤΟΛΟΥ"} />}*/}

          <AlertCardHandler
            isEnable={cardOpen === 8 || cardOpen === -1}
            close={cardOpen === 8 && closeCards}
            title="ΔΙΑΧΕΙΡΙΣΗ ΣΤΟΛΟΥ"
            classBgColor={classes.fleeto_bg_color}
            typeId={8}
            showAnimation={props.showAnimation}
            closeSidePanel={props.close}
            alertsList={props.alertsList.filter(
              (item) => item.categ_id === "1"
            )}
          />
        </Box>

        <Box
          className={
            props.alertsList.filter(
              (item) => item.categ_id === "2" && item.history.length === 0
            ).length > 0
              ? [classes.alert_box, classes.alarm_box].join(" ")
              : classes.alert_box
          }
          title="ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ"
          onClick={() => cardOpen !== 1 && cardOpen !== -1 && setCardOpen(1)}
        >
          <AlertCardHandler
            isEnable={cardOpen === 1 || cardOpen === -1}
            close={cardOpen === 1 && closeCards}
            title="ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ ΔΕΔΟΜΕΝΑ"
            classBgColor={classes.envi_bg_color}
            typeId={1}
            alarm={alarmBox}
            showAnimation={props.showAnimation}
            closeSidePanel={props.close}
            alertsList={props.alertsList.filter(
              (item) => item.categ_id === "2"
            )}
          />
        </Box>
        <Box
          className={
            props.alertsList.filter(
              (item) => item.categ_id === "3" && item.history.length === 0
            ).length > 0
              ? [classes.alert_box, classes.alarm_box].join(" ")
              : classes.alert_box
          }
          title="ΚΙΝΗΣΗ"
          onClick={() => cardOpen !== 11 && cardOpen !== -1 && setCardOpen(11)}
        >
          <AlertCardHandler
            isEnable={cardOpen === 11 || cardOpen === -1}
            close={cardOpen === 11 && closeCards}
            title="ΕΛΕΓΧΟΜΕΝΗ ΣΤΑΘΜΕΥΣΗ"
            classBgColor={classes.parking_bg_color}
            typeId={11}
            alarm={alarmBox}
            showAnimation={props.showAnimation}
            closeSidePanel={props.close}
            alertsList={props.alertsList.filter(
              (item) => item.categ_id === "3"
            )}
          />
        </Box>
        <Box
          className={
            props.alertsList.filter(
              (item) => item.categ_id === "4" && item.history.length === 0
            ).length > 0
              ? [classes.alert_box, classes.alarm_box].join(" ")
              : classes.alert_box
          }
          title="ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ"
          onClick={() => cardOpen !== 9 && cardOpen !== -1 && setCardOpen(9)}
        >
          <AlertCardHandler
            isEnable={cardOpen === 9 || cardOpen === -1}
            close={cardOpen === 9 && closeCards}
            title="ΠΛΗΡΟΤΗΤΑ ΚΑΔΩΝ"
            classBgColor={classes.bins_bg_color}
            typeId={9}
            alarm={alarmBox}
            showAnimation={props.showAnimation}
            closeSidePanel={props.close}
            alertsList={props.alertsList.filter(
              (item) => item.categ_id === "4"
            )}
          />
        </Box>
      </MuuriComponent>
    </div>
  );
};

export default AlertsPlaceholder;
