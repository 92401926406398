import classes from "./HoverCard.module.css";

const HoverCard = (props) => {
  return (
    <div className={classes.hover_card_container}>
      <section>
        <h1>{props.title}</h1>
        <ul>
          {props.history.map((item) => (
            <li>
              {item.action} στις {item.date_change}
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default HoverCard;
