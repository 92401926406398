import classes from "./Frame.module.css";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoadingCircle } from "./../components/UI/spinners/LoadingSpinners";
import { Fragment } from "react";

const iframesList = {
  1: "http://88.99.24.44:8080/zitsa/#/viewer/leaflet/553",
  2: "http://88.99.24.44:8080/zitsa/#/viewer/leaflet/568",
  3: "http://88.99.24.44:8080/zitsa/#/viewer/leaflet/491",
  4: "http://88.99.24.44:8080/odofotismos/#/viewer/leaflet/527",
  5: "http://88.99.24.44:8080/gewtemachio/#/viewer/leaflet/519",
};

const Frame = () => {
  let history = useLocation();
  const [frameId, setFrameId] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const tempId = new URLSearchParams(history.search).get("id");

    let tempFrameId = -1;
    if (!isNaN(tempId) && parseInt(tempId) >= 0 && parseInt(tempId) <= 5)
      tempFrameId = parseInt(tempId);

    if (tempFrameId !== -1) setFrameId(tempId);
    setIsLoading(false);
  }, [history]);
  return (
    <Fragment>
      {!isLoading && (
        <iframe
          frameBorder="0"
          src={iframesList[frameId]}
          title="Map Alexandroupoli"
          className={classes.iframeContainer}
        ></iframe>
      )}
      {isLoading && <LoadingCircle />}
    </Fragment>
  );
};

export default Frame;
