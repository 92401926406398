import classes from "./VerticalTitle.module.css";
import Title from "../../UI/box/title/Title";

const VerticalTitle = (props) => {
  return (
    <div className={classes.sticky_title}>
      <Title
        icon={props.titleIcon}
        title={props.title}
        styleIcon={props.color}
        className={[classes.info_title, classes.height_unset].join(" ")}
      />
      <hr className={classes.gradient_line_style} />
    </div>
  );
};

export default VerticalTitle;
