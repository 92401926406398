import React from "react";
import { GoogleMap } from "@react-google-maps/api";
import { MapContext } from "@react-google-maps/api";
import { withRouter } from "react-router-dom";

class Spiderfy extends React.Component {
  constructor(props) {
    super(props);
    const oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);

    this.oms = new oms.OverlappingMarkerSpiderfier(
      MapContext._currentValue, // 1*
      {}
    );

    this.markerNodeMounted = this.markerNodeMounted.bind(this);
  }

  async markerNodeMounted(ref) {
    setTimeout(() => {
      //3*
      // this.oms.addMarker(ref.marker); // 2*
      // window.google.maps.event.addListener(ref.marker, "spider_click", (e) => {
      //   if (this.props.onSpiderClick) this.props.onSpiderClick(e);
      // });
      console.log("test");

      if (ref) {
        this.oms.addMarker(ref.marker);

        window.google.maps.event.addListener(
          ref.marker,
          "spider_click",
          (e) => {
            if (ref.marker.title) {
              console.log(ref.marker.title, this.props.history);
              this.props.history.push(
                `/map?typeId=10&sensorId=${ref.marker.title}`
              );
            }
          }
        );
      }
    }, 2000);
  }

  render() {
    return React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, { ref: this.markerNodeMounted })
    );
  }
}

export default withRouter(Spiderfy);

// import React from "react";
//
// import { MapContext } from "@react-google-maps/api";
//
// const Spiderfy = (props) => {
//   React.useEffect(() => {
//     const oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
//     this.oms = new oms.OverlappingMarkerSpiderfier(
//       MapContext._currentValue, // 1*
//       {}
//     );
//   }, []);
//
//   const markerNodeMounted = async (ref) => {
//     console.log("ref :", ref);
//     console.log("ref.state.marker :", ref);
//     // this.oms.addMarker(ref.state.marker);
//     // window.google.maps.event.addListener(marker, "spider_click", e => {
//     //   if (this.props.onSpiderClick) this.props.onSpiderClick(e);
//     // });
//   };
//
//   return React.Children.map(props.children, (child) =>
//     React.cloneElement(child, { ref: markerNodeMounted })
//   );
// };
//
// export default Spiderfy;
