import classes from "./SmallStatistics.module.css";
import { useEffect, useState } from "react";
import ChartLine from "../../chartsjs/ChartLine";
import { useLocation } from "react-router-dom";
import { LoadingRise } from "../../../UI/spinners/LoadingSpinners";

const baseUrl = "https://zitsa.smartiscity.gr";
const contentUrl = "/api/api.php";
const actionUrl = "func=stats";

const SmallStatistics = (props) => {
  const [activeCategory, setActiveCategory] = useState(1);
  const [statisticData, setStatisticData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();

  const controller = new AbortController();
  const { signal } = controller;

  const selectCategory = (category) => {
    setActiveCategory(category);
  };

  useEffect(() => {
    const sensorId = new URLSearchParams(search).get("sensorId");

    if (sensorId)
      getSensorStats(sensorId).catch((error) => console.warn(error));
  }, [search, activeCategory]);

  const getSensorStats = async (sensorId) => {
    console.log("ir8a", sensorId, activeCategory);
    setStatisticData([]);
    setLoading(true);
    const response = await fetch(
      `${baseUrl}${contentUrl}?${actionUrl}&sensor=${sensorId}&period=${activeCategory}`,
      { signal }
    ).catch((error) => console.warn(error));
    if (response === undefined || !response.ok) {
      setLoading(false);
      return;
    }

    const data = await response.json();
    const statsData = data.filter((item) =>
      props.loop.includes(item.description)
    );
    setStatisticData(statsData);
    setLoading(false);
    console.log(statsData);
  };

  return (
    <div className={classes.small_statistics_container}>
      <div>
        <button
          type={"button"}
          className={
            activeCategory === 1 ? classes.active : classes.button_switch
          }
          onClick={() => selectCategory(1)}
        >
          Ημερήσια
        </button>
        <button
          type={"button"}
          className={
            activeCategory === 3 ? classes.active : classes.button_switch
          }
          onClick={() => selectCategory(3)}
        >
          Εβδομαδιαία
        </button>
      </div>

      {!("loop" in props) && (
        <ChartLine
          size={"small"}
          data={{
            parameter_id: "28",
            name: "Κάδοι",
            description: "Πληρότητα",
            unit: "%",
            dec: "1",
            min: "72.00",
            max: "100.00",
            avg: "93.46",
            details: [
              {
                date_insert: "2021-11-28",
                hour_insert: "23",
                datetime: "28/11/2021 23:00:00",
                val: "100.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "22",
                datetime: "28/11/2021 22:00:00",
                val: "100.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "21",
                datetime: "28/11/2021 21:00:00",
                val: "100.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "20",
                datetime: "28/11/2021 20:00:00",
                val: "94.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "19",
                datetime: "28/11/2021 19:00:00",
                val: "94.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "18",
                datetime: "28/11/2021 18:00:00",
                val: "100.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "17",
                datetime: "28/11/2021 17:00:00",
                val: "100.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "16",
                datetime: "28/11/2021 16:00:00",
                val: "100.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "15",
                datetime: "28/11/2021 15:00:00",
                val: "100.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "14",
                datetime: "28/11/2021 14:00:00",
                val: "100.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "13",
                datetime: "28/11/2021 13:00:00",
                val: "87.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "12",
                datetime: "28/11/2021 12:00:00",
                val: "82.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "11",
                datetime: "28/11/2021 11:00:00",
                val: "72.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "10",
                datetime: "28/11/2021 10:00:00",
                val: "82.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "9",
                datetime: "28/11/2021 09:00:00",
                val: "93.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "8",
                datetime: "28/11/2021 08:00:00",
                val: "93.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "7",
                datetime: "28/11/2021 07:00:00",
                val: "100.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "6",
                datetime: "28/11/2021 06:00:00",
                val: "93.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "5",
                datetime: "28/11/2021 05:00:00",
                val: "100.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "4",
                datetime: "28/11/2021 04:00:00",
                val: "93.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "3",
                datetime: "28/11/2021 03:00:00",
                val: "93.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "2",
                datetime: "28/11/2021 02:00:00",
                val: "93.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "1",
                datetime: "28/11/2021 01:00:00",
                val: "87.000",
              },
              {
                date_insert: "2021-11-28",
                hour_insert: "0",
                datetime: "28/11/2021 00:00:00",
                val: "87.000",
              },
            ],
          }}
          type={1}
        />
      )}

      {statisticData.map((item, index) => (
        <ChartLine
          key={index}
          size={"small"}
          data={item}
          type={activeCategory}
        />
      ))}

      {loading && (
        <div style={{ minHeight: "calc(369px*3)" }}>
          <div style={{ minHeight: "369px", position: "relative" }}>
            <LoadingRise />
          </div>
        </div>
      )}
    </div>
  );
};
export default SmallStatistics;
