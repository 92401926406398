import classes from "./VerticalSidePanel.module.css";

const VerticalSidePanel = (props) => {
  return (
    <div
      id={"verticalPanel"}
      className={
        props.show
          ? [
              classes.vertical_side_panel,
              classes.side_panel_height,
              props.className,
            ].join(" ")
          : [classes.vertical_side_panel, props.className].join(" ")
      }
    >
      <span
        onClick={() => props.close()}
        className={classes.close_button_sticky}
      >
        <p className={classes.close_button}>x</p>
      </span>
      {props.children}
    </div>
  );
};

export default VerticalSidePanel;
