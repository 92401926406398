import classes from "./TermsOfUse.module.css";
import { Link } from "react-router-dom";

const TermsOfUseEl = () => {
  return (
    <div className={`${classes.privacy}`}>
      <div>
     <p> <span ><h4>ΤΜΗΜΑ 1</h4></span></p>

     <p><span ><h4>ΔΗΛΩΣΗ ΠΡΟΣΒΑΣΙΜΟΤΗΤΑΣ</h4></span></p>

     <p><span >Ο <b>ΔΗΜΟΣ ΖΙΤΣΑΣ </b>δεσμεύεται να καθιστά τον οικείο ιστότοπο: zitsa.smartcity.gr προσβάσιμο, σύμφωνα με τις προβλέψεις του ν. 4727/2020 (ΦΕΚ 184 Α’ 23-09-2020), Κεφάλαιο Η’ «ΨΗΦΙΑΚΗ ΠΡΟΣΒΑΣΙΜΟΤΗΤΑ (ΕΝΣΩΜΑΤΩΣΗ ΣΤΗΝ ΕΛΛΗΝΙΚΗ ΝΟΜΟΘΕΣΙΑ ΤΗΣ ΟΔΗΓΙΑΣ (ΕΕ) 2016/2102 ΤΟΥ ΕΥΡΩΠΑΪΚΟΥ ΚΟΙΝΟΒΟΥΛΙΟΥ ΚΑΙ ΤΟΥ ΣΥΜΒΟΥΛΙΟΥ, ΤΗΣ 26ΗΣ ΟΚΤΩΒΡΙΟΥ 2016, ΓΙΑ ΤΗΝ ΠΡΟΣΒΑΣΙΜΟΤΗΤΑ ΤΩΝ ΙΣΤΟΤΟΠΩΝ ΚΑΙ ΤΩΝ ΕΦΑΡΜΟΓΩΝ ΓΙΑ ΦΟΡΗΤΕΣ ΣΥΣΚΕΥΕΣ ΤΩΝ ΟΡΓΑΝΙΣΜΩΝ ΤΟΥ ΔΗΜΟΣΙΟΥ ΤΟΜΕΑ)» που ενσωματώνει στην εθνική έννομη τάξη την Οδηγία (ΕΕ) 2016/2012.</span>
     </p>

<p><span >Η παρούσα δήλωση προσβασιμότητας εφαρμόζεται στα εξής: στον ιστότοπο </span><i><span >zitsa.smartcity.gr</span></i></p>

<p><b><h4>Κατάσταση συμμόρφωσης</h4></b></p>

<p><span>Ο παρών ιστότοπος <i>zitsa.smartcity.gr</i> συνάδει πλήρως με τις Οδηγίες για την προσβασιμότητα στο περιεχόμενο Ιστού (WCAG 2.1 ΑΑ).</span></p>

<p><b><h4>Κατάρτιση της παρούσας δήλωσης προσβασιμότητας</h4></b></p>

<p><span >Η παρούσα δήλωση καταρτίστηκε την 21-11-2022.</span></p>

<p><span >Η πραγματική αξιολόγηση της συμμόρφωσης του ιστοτόπου προς τις απαιτήσεις της Οδηγίας (ΕΕ) 2016/2102 πραγματοποιήθηκε με αυτοαξιολόγηση διενεργηθείσα από τον οργανισμό του δημόσιου τομέα και με την χρήση του</span><a href="https://userway.org/"><span >UserWay Website Accessibility Widget</span></a><span > </span>
</p>
<p><span >Τελευταία αναθεώρηση της δήλωσης στις 05-12-2022..</span></p>

<p><b><h4>Υποβολή παρατηρήσεων και στοιχεία επικοινωνίας</h4></b></p>

<p><span >Σύμφωνα με τις προβλέψεις του ν. 4727/2020 (ΦΕΚ 184 Α’ 23-09-2020), άρθρο 45, κάθε ενδιαφερόμενος έχει δικαίωμα να υποβάλλει παρατηρήσεις ή αίτηση για ενημέρωση ως προς την κατάσταση συμμόρφωσης ή αίτηση για πληροφορίες που ελλείπουν.</span>
</p>
<p><span > αίτηση για ενημέρωση ως προς την κατάσταση συμμόρφωσης ή αίτηση για πληροφορίες που ελλείπουν.</span>
</p>
<p><span >Για θέματα προσβασιμότητας και για τη διεκπεραίωση αιτημάτων που υποβάλλονται μέσω του μηχανισμού υποβολής παρατηρήσεων υποβάλλονται στο e-mail: zitsa@zitsa.gov.gr: </span></p>

<p><b><h4>Διαδικασία εκτέλεσης</h4></b></p>

<p><span >Η αρμόδια διεύθυνση του <b>ΔΗΜΟΥ ΖΙΤΣΑΣ</b> απαντά στον ενδιαφερόμενο με πρόσφορο τρόπο.
</span></p>

<p><span >Σε περίπτωσης μη ικανοποιητικής απάντησης στην ενημέρωση ή στα αιτήματα ο ενδιαφερόμενος δικαιούται να αποταθεί σύμφωνα με την πρόβλεψη στις αρμόδιες θεσμικές αρχές, όπως με την υποβολή σχετικής Αναφοράς στον Συνήγορο του Πολίτη (https://www.synigoros.gr).</span>
</p>
<p><span ><h4>ΤΜΗΜΑ 2</h4></span></p>

<p><b><h4>Ενεργοποίηση του Μενού Προσβασιμότητας</h4></b></p>

<p><span>Το μενού προσβασιμότητας του ιστοτόπου <i>zitsa.smartcity.gr</i> μπορεί να ενεργοποιηθεί κάνοντας κλικ στο εικονίδιο του μενού προσβασιμότητας που εμφανίζεται στο πάνω μέρος της σελίδας. Αφού ενεργοποιήσετε το μενού προσβασιμότητας, περιμένετε λίγο για να φορτωθεί το μενού προσβασιμότητας στο σύνολό του.
Το zitsa.smartcity.gr συνεχίζει τις προσπάθειές του για να βελτιώνει συνεχώς την προσβασιμότητα του ιστότοπου με την πεποίθηση ότι είναι η συλλογική ηθική μας υποχρέωση να επιτρέπουμε την απρόσκοπτη, προσβάσιμη και απρόσκοπτη χρήση και για όσους από εμάς έχουν αναπηρία.
Σε μια συνεχή προσπάθεια για συνεχή βελτίωση και αποκατάσταση προβλημάτων προσβασιμότητας, σαρώνουμε τακτικά το zitsa.smartcity.gr με το σαρωτή προσβασιμότητας του UserWay για να εντοπίσουμε και να διορθώσουμε κάθε πιθανό εμπόδιο προσβασιμότητας στον ιστότοπο. Παρά τις προσπάθειές μας να κάνουμε όλες τις σελίδες και το περιεχόμενο στο zitsa.smartcity.gr πλήρως προσβάσιμο, κάποιο περιεχόμενο ενδέχεται να μην έχει ακόμη προσαρμοστεί πλήρως στα αυστηρότερα πρότυπα προσβασιμότητας.
</span></p>
        <p style={{ float: "right", fontWeight: 600, marginTop: 20 }}>
          <Link to={"/"}>Επιστροφή στην Αρχική</Link>
        </p>
      </div>
    </div>
  );
};

export default TermsOfUseEl;