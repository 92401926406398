import classes from "./ActionBox.module.css";

import { useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import Card from "../UI/cards/Card";

const CustomCheckBox = withStyles({
  root: {
    color: grey[50],
    "&$checked": {
      color: grey[50],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ActionsBox = ({ alertDetails }) => {
  const [inform, setInform] = useState(false);
  const [watch, setWatch] = useState(false);
  const [complete, setComplete] = useState(false);

  const submit = async () => {
    const url = watch
      ? `https://smartcity.zitsa.gov.gr/api/api.php?func=alertaction&alert_id=${alertDetails.alert_id}&action_id=5`
      : `https://smartcity.zitsa.gov.gr/api/api.php?func=alertaction&alert_id=${alertDetails.alert_id}&action_id=6`;
    const postAction = await fetch(url, { method: "POST" }).catch((error) =>
      console.warn(error)
    );

    if (postAction === undefined || !postAction.ok) return;

    const data = await postAction.json();
    console.log(inform, watch, complete);
    console.log(data);
  };

  useEffect(() => {
    if (alertDetails.history) {
      let tempInform = false;
      let tempWatch = false;
      let tempComplete = false;
      alertDetails.history.map((item) => {
        if (item.action_id === "5") tempWatch = true;
        else if (item.action_id === "6") tempInform = true;
      });
      setWatch(tempWatch);
      setInform(tempInform);
      setComplete(tempComplete);
    }
  }, [alertDetails]);

  const clear = () => {
    setInform(false);
    setWatch(false);
    setComplete(false);
  };

  return (
    <Card
      className={classes.actions_container}
      id={"actionsAlert"}
      onCancel={clear}
      onConfirm={submit}
    >
      <FormControlLabel
        control={
          <CustomCheckBox
            checked={inform}
            onChange={() =>
              setInform((prev) => {
                return !prev;
              })
            }
          />
        }
        label="Ενημέρωση"
        labelPlacement="top"
      />
      <FormControlLabel
        control={
          <CustomCheckBox
            checked={watch}
            onChange={() =>
              setWatch((prev) => {
                return !prev;
              })
            }
          />
        }
        label="Παρακολούθηση"
        labelPlacement="top"
      />
      <FormControlLabel
        control={
          <CustomCheckBox
            checked={complete}
            onChange={() =>
              setComplete((prev) => {
                return !prev;
              })
            }
          />
        }
        label="Ολοκλήρωση"
        labelPlacement="top"
      />
    </Card>
  );
};

export default ActionsBox;
