import React from "react";
import classes from "./Title.module.css";
import { Link } from "react-router-dom";
import Tooltip from "./../../tooltip/Tooltip";
import { useStore } from "../../../../hooks/store";

const Title = (props) => {
  const dispatch = useStore(false)[1];
  return (
    <div className={[classes.flexContainer, props.className].join(" ")}>
      {props.icon && (
        <i
          className={props.icon}
          style={
            props.styleIcon
              ? { color: "white", fontSize: "30px", ...props.styleIcon }
              : { color: "white", fontSize: "30px" }
          }
        />
      )}
      {!props.icon && <div style={{ minWidth: "32px" }} />}

      <div className={classes.title}>
        {props.link && (
          <Link
            onClick={() => props.id && dispatch("CLICKED_TYPE", props.id)}
            to={props.link}
          >
            {props.title}
          </Link>
        )}
        {props.externalLink && (
          <a href={props.externalLink} target="_blank" rel="noreferrer">
            {props.title}
          </a>
        )}

        {!props.link && !props.externalLink && props.title}

        {props.infoContext && (
          <span className={classes.triggerTooltip}>
            <i
              className="fa fa-info-circle"
              style={{
                color: "white",
                margin: "2px 5px 0",
                position: "absolute",
              }}
            />
            <Tooltip tooltipContent={props.infoContext} />
          </span>
        )}
      </div>
      <div
        className={[
          classes.lastElementPlaceholder,
          classes.triggerTooltip,
        ].join(" ")}
      >
        {props.element}
        <Tooltip
          tooltipContent="Σύρετε από εδώ"
          className={classes.dragAndDropTooltip}
        />
      </div>
    </div>
  );
};

export default Title;
