import DotLoader from "react-spinners/DotLoader";
import CircleLoader from "react-spinners/CircleLoader";
import RiseLoader from "react-spinners/RiseLoader";
import ClockLoader from "react-spinners/ClockLoader";
import { css } from "@emotion/react";

const override = css`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
`;

const overrideBig = css`
  position: absolute;
  top: calc(50% - 23.5px);
  left: calc(50% - 135px);
`;

const overrideInButton = css`
  position: absolute;
  margin-left: 2.5px;
`;

export const LoadingDot = () => {
  return <DotLoader color={"white"} loading size={60} css={override} />;
};

export const LoadingCircle = () => {
  return <CircleLoader color={"white"} loading size={50} css={override} />;
};

export const LoadingRise = () => {
  return <RiseLoader color={"#E3728F"} loading size={50} css={overrideBig} />;
};

export const LoadingClock = () => {
  return (
    <ClockLoader color={"black"} loading size={15} css={overrideInButton} />
  );
};
