const baseUrl = "https://zitsa.smartiscity.gr";
export const feedTypes = async (signal) => {
  console.log("feed types");
  const req = await fetch(baseUrl + "/api/api.php?func=sensortypes", {
    signal,
  }).catch((error) => console.warn(error));
  if (req === undefined || !req.ok) return [];
  const response = await req.json();
  console.log(response);
  const data = response.map((item) => {
    let iconContent = null;
    if (item.sensortype_icon) {
      const tempIcon = item.sensortype_icon.split("-")[0];
      iconContent = tempIcon + " " + item.sensortype_icon;
    }
    return {
      id: item.sensortype_id,
      name: item.sensortype_name,
      icon: iconContent,
      tooltip: item.tooltip,
      color: item.sensortype_color,
      url: item.url,
      isActive: false,
    };
  });

  return data;
};
export const feedSensors = async (signal) => {
  console.log("feed sensors");
  const req = await fetch(baseUrl + "/api/api.php?func=allsensors", {
    signal,
  }).catch((error) => console.warn(error));
  if (req === undefined || !req.ok) return [];
  const response = await req.json();
  console.log(response);

  const data = response
    .filter(
      (item) =>
        item.sensortype_id !== "7" &&
        item.sensortype_id !== "8" &&
        item.sensortype_id !== "9" &&
        item.sensortype_id !== "10"
    )
    .map((item) => {
      let title = item.sensor_address ? item.sensor_address.split(", ") : "";
      if (title.length > 1) title = title[1];
      else title = item.mysensor_name;

      return {
        id: item.mysensor_id,
        typeId: item.sensortype_id,
        boxTitle: title,
        title: item.mysensor_name,
        group: item.group,
        groupName: item.group_name,
        smallMarker: item.marker,
        locationId: item.location_id,
        address: item.sensor_address,
        color: item.sensortype_color,
        position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
        metrics: [],
        isActive: false,
      };
    });

  return data;
};
