import * as React from "react";

function SvgUrbanPlanning(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      overflow="hidden"
    >
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H96V96H0z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip0)">
        <path d="M66.002 59.927v-2h-7v11h-2v2h19v-34h-8.441l-2 2h8.441v10H58v2h16.002v7h-3v2h3v9h-13v-9h5z"></path>
        <path d="M79.561 24.927l-2 2H85v53H20.89a6.984 6.984 0 002.03-4l.08-1v-48h33.478l2-2H23v-3a7 7 0 10-14 0v53l.08 1a7 7 0 006.476 5.973L87 81.927v-57zm-68.561-3a5 5 0 0110 0v48.11a6.979 6.979 0 00-10 0zm0 53a5 5 0 1110 0 5 5 0 01-10 0z"></path>
        <path d="M44.5 38.927l1.996-2H32v34h19.002v-2h-2v-11h-7v2h5v9H34v-9h3.002v-2H34V51h6.153l.663-2H34V38.927h10.5z"></path>
        <path d="M43.5 50.43l9.42-3.107 29.445-29.541a1.868 1.868 0 00.049-2.762l-3.5-3.5a1.967 1.967 0 00-2.811 0L46.609 41.06zm34.008-37.488l3.433 3.435-3.075 3.075-3.435-3.435zm-4.825 4.824l.334-.335 3.472 3.469-.347.372L54.517 42.9a4.526 4.526 0 00-3.477-3.448zM48.359 42.138l.409-.41a2.6 2.6 0 011.337-.378 2.5 2.5 0 011.781.746c.852.826.983 2.147.31 3.125l-.352.352-4.177 1.378-.679-.679z"></path>
      </g>
    </svg>
  );
}

export default SvgUrbanPlanning;